export default {
    calcBabyAbility(res) {
        var scaleJson = require('./scaleJson/babyAblilityAgeScale.json');
        var obj = {}
        var independentActivitiesProblem = scaleJson.independentActivitiesProblem
        var movementProblem = scaleJson.movementProblem
        var communicateProblem = scaleJson.communicateProblem
        var operationsProblem = scaleJson.operationsProblem
        var collectiveActivityProblem = scaleJson.collectiveActivityProblem
        var selfManageProblem = scaleJson.selfManageProblem
        res.forEach(item => {
            if(!item){
                return
            }
            item.result = JSON.parse(item.result)

            obj[item.functionAssessmentId] = []
            var independentActivitieScore = 0
            var communicateScore = 0
            var movementScore = 0
            var operationsScore = 0
            var collectiveActivityScore = 0
            var selfManageScore = 0
            for (var key in item.result) {
                if (item.result[key] == 1 && independentActivitiesProblem.includes(Number(key))) {
                    independentActivitieScore++
                }
                if (item.result[key] == 1 && communicateProblem.includes(Number(key))) {
                    communicateScore++
                }
                if (item.result[key] == 1 && movementProblem.includes(Number(key))) {
                    movementScore++
                }
                if (item.result[key] == 1 && operationsProblem.includes(Number(key))) {
                    operationsScore++
                }
                if (item.result[key] == 1 && collectiveActivityProblem.includes(Number(key))) {
                    collectiveActivityScore++
                }
                if (item.result[key] == 1 && selfManageProblem.includes(Number(key))) {
                    selfManageScore++
                }
            }
            obj[item.functionAssessmentId] = [independentActivitieScore, movementScore, operationsScore, communicateScore, collectiveActivityScore, selfManageScore]
        })
        return obj;
    },
    calcOtherResult(scale, result,onlyTotal) {
        var arr=[]
        for (let k in scale) {
            scale[k].scoreObj = {}
            scale[k].num = {}
            scale[k].total = 0
            scale[k].values.forEach(item => {
                for (let i in result) {
                    if (item.num == i) {
                        item.score = result[i]
                        if (!scale[k].scoreObj[item.score]) {
                            scale[k].scoreObj[item.score] = [item.score]
                        } else {
                            scale[k].scoreObj[item.score].push(item.score)
                        }
                        if (!scale[k].num[item.score]) {
                            scale[k].num[item.score] = [item.num]
                        } else {
                            scale[k].num[item.score].push(item.num)
                        }
                        scale[k].total += Number(item.score)

                    }
                }

            })
            arr.push(scale[k].total)
        }
        if(!onlyTotal){
            return scale
        }else{
            return arr
        }

    }
}
