import Vue from 'vue'
import Element from "element-ui"
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

import gobal from "./globalFun"
import vueEsign from 'vue-esign'
Vue.use(vueEsign)
import {request,download} from "./axios";
import utils from "./utils";
import type from "./utils/type"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import calcScaleScore from "./utils/calcScaleScore";
Vue.use(VueAwesomeSwiper)

Vue.prototype.$axios = request;
Vue.prototype.$download = download;
Vue.prototype.$utils = utils;
Vue.prototype.$type = type;
Vue.config.productionTip = false
Vue.prototype.$calcScaleScore=calcScaleScore
Vue.use(Element)
import './utils/dialog'
const vue=new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vue
