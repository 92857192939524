/*
 * @Description:
 * @Autor: Fhz
 * @Date: 2024-11-17 15:48:22
 * @LastEditors: Fhz
 * @LastEditTime: 2024-12-07 18:36:39
 */
import axios from "axios";
import router from "./router";
import Element from "element-ui";
// axios.defaults.baseURL = 'http://123.58.211.180/iepback'
// axios.defaults.baseURL = 'http://192.168.0.106:8089'
// axios.defaults.baseURL = 'http://qdchxiep.qdedu.net/iepback' //青岛
// axios.defaults.baseURL = "http://fbx66t.natappfree.cc";
axios.defaults.baseURL = "https://home.raiep.cn/iepback";
// axios.defaults.baseURL = 'http://beautifulcountry.cloudaware.cn:8080/iepback/' //上微
// axios.defaults.baseURL = 'http://192.168.0.99/iepback'   //苏州高新
const request = axios.create({
  timeout: 5000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});
const download = axios.create({
  timeout: 10000,
});
download.interceptors.request.use((config) => {
  config.headers["token"] = localStorage.getItem("token");
  config.responseType = "blob";
  return config;
});
download.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      let blob = new Blob([response.data]);
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      let str = response.headers["content-disposition"].split(";")[1];
      let filename = str.split("filename=")[1];
      link.setAttribute("download", decodeURIComponent(filename)); // 解码，这里也可以自定义下载的文件名字，如link.setAttribute('download', 'xxxxxdownload.xls')
      document.body.appendChild(link);
      link.click(); //用新窗口打开window.open(link.click())，但是下载完成后不会先get请求那样自动关闭窗口
      Element.Message.success("下载成功", { duration: 3 * 1000 });
    } else {
      Element.Message.error("下载出错", { duration: 3 * 1000 });
    }
  },
  (error) => {
    Element.Message.error("下载出错", { duration: 3 * 1000 });
    return Promise.reject(error);
  }
);

request.interceptors.request.use((config) => {
  config.headers["token"] = localStorage.getItem("token");
  return config;
});

request.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (res.code === 200) {
      return response.data;
    } else {
      Element.Message.error(res.msg ? res.msg : "系统异常！", {
        duration: 3 * 1000,
      });
      return Promise.reject(response.data.msg);
    }
  },
  (error) => {
    if (error.message == "Network Error") {
      Element.Message.error("网络错误", { duration: 3 * 1000 });
      return;
    }
    if (error.response.data) {
      error.message = error.response.data.msg;
    }
    if (error.response.status === 401) {
      router.push("/login");
    }
    Element.Message.error(error.message, { duration: 3 * 1000 });
    return Promise.reject(error);
  }
);
export { request, download };
