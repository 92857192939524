<template>
    <div id="app">
        <router-view v-if="isRouterAlive"/>
    </div>
</template>
<script>
    export default {
        name: "App",
        data() {
            return {
                isRouterAlive: true,
            }
        },
        provide() {
            return {
                refresh: this.refresh
            }
        },
        methods: {
            refresh() {
                this.isRouterAlive = false;
                this.$nextTick(() => {
                    this.isRouterAlive = true;
                })
            },
            //  修改标题
            editTitle(loginType) {
                var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = 'ronghe.ico';
                document.title = '新特教智慧服务平台'
                if (localStorage.getItem('loginName') && loginType != 1) {
                    document.title = '资产管理平台'
                    link.href = 'favicon.ico';
                    document.getElementsByTagName('head')[0].appendChild(link)
                }
                // if (!loginType && !localStorage.getItem('loginName')) {
                //     // link.href = 'favicon1.ico';
                //     link.href = 'ronghe.ico';
                //     document.title = 'IEP综合数据管理平台'
                // }
            }
        },
        watch: {
            $route(to, from) {
                var loginType = to.meta.loginType
                this.editTitle(loginType)
                //免受权
                var noAuthorize = false
                this.$router.options.routes.forEach(r => {
                    if (r.path == to.path && r.noAuthorize != null) {
                        noAuthorize = r.noAuthorize
                        return
                    }
                })
                if (!noAuthorize && to.path != '/' && to.name != null) {
                    let obj = {
                        name: to.name,
                        title: to.meta.title
                    }
                    this.$store.commit("addTab", obj)
                }
                if (to.path == '/') {
                    this.$router.push({name: 'Index'})
                }
            },
        },
        mounted() {
            // this.$utils.getSchool().then(info=>{
            //     localStorage.setItem('school', JSON.stringify(info))
            // })
        }
    }
</script>

<style>

    .el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #3051d3 !important;
    }
    @import url("assets/main.css");
    html, body, #app {
        font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif;
        height: 100%;
        padding: 0;
        margin: 0;
        font-size: 15px;
        /*overflow-y: hidden;*/
    }
    .el-page-header__content{
        font-size: 16px !important;
    }
    /*去除upload组件过渡效果*/
    .el-upload-list__item {
        transition: none !important;
    }
    .select-btn {
        width: 80px;
        /*height: 40px;*/
        border: solid 1px #EBEEF5;
        text-align: center;
        /*line-height: 40px;*/
        background-color: #fff;

        cursor: pointer;
        /*margin-top: 10px;*/
    }

    .select-btn:last-child {
        margin: 0 30px;
    }

    .select-btn.checked {
        background-color: #e1f3d8;
        color: rgb(32, 175, 91);
        transition: all 100ms ;
    }
    .single-line {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        cursor: pointer;
    }

</style>
